import React, { useEffect, useState } from 'react'

import { getListVistitor, createVisitor, deleteVisitor, editVisitor } from "../../apis/Functions/visitor";
import VisitorView from "./VisitorView";
import { getTime, getDate } from '../../config/Function'
import { useSnackbar } from 'notistack'



function createData(id, date, startTime, endTime, name, sdt, cmt, employee, sdtEmployee) {
    return { id, date, startTime, endTime, name, sdt, cmt, employee, sdtEmployee };
}


const VisitorContainer = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [numberPage, setNumberPage] = useState(2);
    const [pageCurrent, setPageCurrent] = useState(1);
    const [listVisitor, setListVisitor] = useState([]);
    const [rows, setRows] = useState([])

    const [itemSelected, setItemSelected] = useState({});


    const onSelectItem = (item) => {
        console.log("------------item", item);
        console.log(listVisitor)
        const selected = listVisitor.find(e => e._id == item.id)
        setItemSelected(selected)
    };


    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };
    const closeUpdateModal = () => {
        setModalUpdate(false);
    };
    const openUpdateModal = () => {
        setModalUpdate(true);
    };
    const closeModalDelete = () => {
        setModalDelete(false);
    };
    const openModalDelete = () => {
        setModalDelete(true);
    };





    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getData()
    }, [pageCurrent])


    useEffect(() => {
        const newList = listVisitor.map(e =>
            createData(
                e._id,
                getDate(e.created_at),
                getTime(e.created_at),
                getTime(e.created_at),
                e.visitor,
                e.mobile,
                e.passport,
                e.contact_point,
                e.contact_point_mobile))
        setRows(newList)
    }, [listVisitor])


    const getData = async () => {
        const res = await getListVistitor({ api_name: 'api.v1.features.visitor.list', page_index: pageCurrent })
        if (res.data) {
            setListVisitor(res.data.data)
            setNumberPage(res.data.meta.total_page)
        }
    }

    const changePageCurrent = (page) => {
        setPageCurrent(page)
    }

    const onSubmitCreate = async (obj) => {
        if (obj) {
            console.log(obj)
            const res = await createVisitor(obj)
            console.log(res)
            if (res.data.code == 400)
                enqueueSnackbar(res.data.message, { variant: "error" });
            else if (res.data.code == 200) {
                enqueueSnackbar("Thêm mới thành công!", { variant: "success" });
                getData()
                closeModal()
            }
        }
    }

    const deleteItem = async () => {

        closeModalDelete()
        const res = await deleteVisitor({ api_name: "api.v1.features.visitor.delete", _id: itemSelected._id })
        if (res.data.code == 400) {
            enqueueSnackbar(res.data.message, { variant: "error" });
        }
        else if (res.data.code == 200) {
            enqueueSnackbar("Xóa mới thành công!", { variant: "success" });
            getData()
        }
    };


    const submitUpdateVisitor = async (obj) => {
        if (obj) {
            console.log(obj)
            const res = await editVisitor(obj)
            console.log("day la res", res)
            // if (res.data && res.data.errorCode === 0) {
            //     enqueueSnackbar("Chỉnh sửa thành công", { variant: "success" });
            //     getData();
            //     closeUpdateModal();
            // } else {
            //     enqueueSnackbar("Lỗi", { variant: "error" });
            // }
            if (res.data.code == 400) {
                enqueueSnackbar(res.data.message, { variant: "error" });
            }
            else if (res.data && res.data.code == 200) {
                enqueueSnackbar("Chỉnh sửa thành công!", { variant: "success" });
                getData()
                closeUpdateModal()
            }
        }
    }


    return (
        <VisitorView
            rows={rows}
            pageCurrent={pageCurrent}
            numberPage={numberPage}
            changePageCurrent={changePageCurrent}
            onSubmitCreate={onSubmitCreate}
            openModal={openModal}
            closeModal={closeModal}
            modalUpdate={modalUpdate}
            modalIsOpen={modalIsOpen}
            closeModalDelete={closeModalDelete}
            openModalDelete={openModalDelete}
            closeUpdateModal={closeUpdateModal}
            openUpdateModal={openUpdateModal}
            modalDelete={modalDelete}
            deleteItem={deleteItem}
            submitUpdateVisitor={submitUpdateVisitor}
            onSelectItem={onSelectItem}
            itemSelected={itemSelected}
        />
    )
}


export default VisitorContainer;
