import React, { useState, useEffect } from 'react';
import { makeStyles, Container, Typography, TextField, Grid, Card, InputLabel, Select, FormControl, Paper } from "@material-ui/core";

import Header from './Header';
import TableMeeting from './TableMeeting';
import { columns } from './Data';
import ModalDelete from '../../Component/Modal/ModalDelete';
import ModalMeeting from './ModalMeeting';
import UpdateMeeting from './UpdateMeeting';
import WrapView from "../WrapView";



const MeetingView = (props) => {
    const { numberPage, pageCurrent, changePageCurrent, rows, listStatus, listRooms, listDepartment, listTypes, listLevel,
        listEmployee, listOption, submitCreateMeeting, submitUpdateMeeting, modalIsOpen, openModal, closeModal, deleteItem, onSelectItem,
        modalDelete, closeModalDelete, openModalDelete, openUpdateModal, closeUpdateModal, modalUpdate, itemSelected,
        getLocationFilter, getKeyWordFilter
    } = props

    console.log(props.itemSelected);


    return (
        <WrapView>
            <Container >
                <Typography style={{ marginTop: 10, marginBottom: 10 }} variant={'h5'} >Danh sách cuộc họp</Typography>
                <Paper elevation={2}>
                    <Grid container >
                        <Grid xs={12} item >
                            <Header
                                openModal={openModal}
                                listRooms={listRooms}
                                listStatus={listStatus}
                                getKeyWordFilter={getKeyWordFilter}
                                getLocationFilter={getLocationFilter}
                            />
                        </Grid>
                        <Grid xs={12} item >
                            <TableMeeting
                                rows={rows}
                                columns={columns}
                                openModal={openModal}
                                openUpdateModal={openUpdateModal}
                                openModalDelete={openModalDelete}
                                onSelectItem={onSelectItem}
                                numberPage={numberPage}
                                pageCurrent={pageCurrent}
                                changePageCurrent={changePageCurrent}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <ModalMeeting
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                openModal={openModal}
                listRooms={listRooms}
                listDepartment={listDepartment}
                listTypes={listTypes}
                listLevel={listLevel}
                listEmployee={listEmployee}
                listOption={listOption}
                submitCreateMeeting={submitCreateMeeting}
            />
            <UpdateMeeting
                modalIsOpen={modalUpdate}
                closeModal={closeUpdateModal}
                openUpdateModal={openUpdateModal}
                listRooms={listRooms}
                listDepartment={listDepartment}
                listTypes={listTypes}
                listLevel={listLevel}
                listEmployee={listEmployee}
                listOption={listOption}
                submitUpdateMeeting={submitUpdateMeeting}
                itemSelected={itemSelected}
            />
            <ModalDelete
                modalIsOpen={modalDelete}
                closeModal={closeModalDelete}
                deleteItem={deleteItem}
            />
        </WrapView>
    )
}

export default MeetingView
