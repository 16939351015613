import React from 'react'

import ReportView from "./ReportView";
const ReportContainer=()=>{


    return(
        <ReportView/>
    )
}


export  default  ReportContainer;
