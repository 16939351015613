export const ColorsSetting = {
  MAIN_COLOR: "#0062E1",
};
export const OneSignalKey = {
  development: "9f81dc21-7054-4f5f-ad53-87f025d2d154",
  production: "9f81dc21-7054-4f5f-ad53-87f025d2d154",
};
export const NetworkSetting = {
  // link dcv
  // ROOT_MOB: "http://222.252.22.174:8080/erp-service-mobile",
  // ROOT_WEB: "http://222.252.22.174:8080/erp-service",
  ROOT_WEB: "http://meeting.dcv.vn/api/rest",

};
