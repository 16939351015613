import React, { useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Card,
  Typography,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  TextField,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DateFnsUtils from "@date-io/date-fns";
import SearchIcon from "@material-ui/icons/Search";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  Calendar,
} from "@material-ui/pickers";
import {
  formatDateTimeDDMM,
  formatDateTimeDDMMTommorrow,
} from "../../config/Function";

const Body = (props) => {
  const [date, changeDate] = useState(new Date());
  const handleChangeDate = (date) => {
    console.log(formatDateTimeDDMM(date));
    console.log(formatDateTimeDDMMTommorrow(date));
    changeDate(date);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
        <Card style={{ border: "1px solid #a27c7c" }}>
          <div
            style={{
              width: "100%",
              paddingBottom: "25px",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid rgb(210, 195, 195)",
                padding: "7px",
              }}
            >
              {`Ngày ${formatDateTimeDDMM(date)}`}
            </div>
            {props.today.map((el) => {
              return (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ padding: "8px", borderBottom: "1px solid #a99898" }}
                  spacing={1}
                >
                  <Grid item xs={2}>
                    <div
                      style={{
                        padding:10,
                        backgroundColor: "#EB5757",
                          color:'white',
                          marginTop:5
                      }}
                    >
                        <Typography  variant={'h6'} style={{textAlign:'center'}} >25/12</Typography>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          margin: "0 auto",
                          fontWeight: "bold",
                        }}
                      >
                        {el.startTime}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <b>{el.nameMeeting}</b>
                    <br />
                    Tạo bởi<span> {el.nameCreate}</span> vào lúc
                    <span> {el.timeCreate}</span>
                    <br />
                    Chủ trì<span> {el.manageRoom} | Địa điểm: </span>
                    <span>{el.place}</span>
                  </Grid>
                  <Grid item xs={2}>
                    <span>Tham gia</span>
                    <br />
                    <h1 style={{ fontSize: "45px", margin: "0px" }}>
                      {el.participateCount}
                    </h1>
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ textAlign: "center", marginTop: "-8px" }}>
                      Quan trọng
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        marginBottom: "-13px",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton
                        aria-label="delete"
                        size="large"
                        style={{ color: "green" }}
                      >
                        <VisibilityIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="large"
                        style={{ color: "#3C8DBC" }}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="large"
                        color="secondary"
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              );
            })}
          </div>
        </Card>
        <br />
        <Card style={{ border: "1px solid #a27c7c" }}>
          <div
            style={{
              width: "100%",
              paddingBottom: "25px",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid rgb(210, 195, 195)",
                padding: "7px",
              }}
            >
              {`Ngày ${formatDateTimeDDMMTommorrow(date)}`}
            </div>
            {props.tommorrow.map((el) => {
              return (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ padding: "8px", borderBottom: "1px solid #a99898" }}
                  spacing={1}
                >
                  <Grid item xs={2}>
                      <div
                          style={{
                              padding:10,
                              backgroundColor: "#EB5757",
                              color:'white',
                              marginTop:5
                          }}
                      >
                          <Typography  variant={'h6'} style={{textAlign:'center'}} >25/12</Typography>
                      </div>
                      <div
                          style={{
                              width: "100%",
                              height: "50px",
                              display: "flex",
                              alignItems: "center",
                          }}
                      >
                          <Typography
                              style={{
                                  margin: "0 auto",
                                  fontWeight: "bold",
                              }}
                          >
                              {el.startTime}
                          </Typography>
                      </div>
                  </Grid>
                  <Grid item xs={5}>
                    <b>{el.nameMeeting}</b>
                    <br />
                    Tạo bởi<span> {el.nameCreate}</span> vào lúc
                    <span> {el.timeCreate}</span>
                    <br />
                    Chủ trì<span> {el.manageRoom} | Địa điểm: </span>
                    <span>{el.place}</span>
                  </Grid>
                  <Grid item xs={2}>
                    <span>Tham gia</span>
                    <br />
                    <h1 style={{ fontSize: "45px", margin: "0px" }}>
                      {el.participateCount}
                    </h1>
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ textAlign: "center", marginTop: "-8px" }}>
                      Quan trọng
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        marginBottom: "-13px",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton
                        aria-label="delete"
                        size="large"
                        style={{ color: "green" }}
                      >
                        <VisibilityIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="large"
                        style={{ color: "#3C8DBC" }}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="large"
                        color="secondary"
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              );
            })}
          </div>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card style={{ border: "1px solid rgb(169, 152, 152)" }}>
          <Grid container alignItems="flex-end" style={{ margin: "0px" }}>
            <Grid item xs={12}>
              <SearchIcon style={{ verticalAlign: "middle" }} />
              <input
                type="text"
                placeholder="Tìm kiếm cuộc họp"
                style={{
                  border: "none",
                  outline: "none",
                  fontSize: "15px",
                  height: "40px",
                }}
              />
            </Grid>
          </Grid>
        </Card>
        <br />
        <Card style={{ border: "1px solid rgb(169, 152, 152)" }}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #a99898",
                width: "100%",
                padding: "5px",
              }}
            >
              Thông báo
            </div>
            <div style={{ padding: "5px" }}>
              Hôm nay cả công ty liên hoan. Yêu cầu mọi người có mặt đầy đủ
            </div>
          </div>
        </Card>
        <br />
        <Card style={{ border: "1px solid rgb(169, 152, 152)" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Calendar date={date} onChange={handleChangeDate}></Calendar>
          </MuiPickersUtilsProvider>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Body;
