import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, TextField, Grid, Card, InputLabel, Select, FormControl, Button } from "@material-ui/core";
import PopupDatePicker from '../../Component/Popup/PopupDatePicker.js'
import Colors from '../../assets/Color'

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    containerAdd: {
        flex: 1,
    }

}));


const VisiterView = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({
        age: '',
        name: 'hai',
    });


    const { openModal } = props
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const onChangeDate = (startDate, endDate) => {
        console.log(startDate + "----" + endDate);
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };


    return (
        <div elevation={2} style={{ padding: 10, paddingRight: 25, paddingLeft: 25, marginBottom: 15 }}>
            <Grid container xs={12} direction={'row'} alignItems={'center'} justify={'space-between'} >
                <Grid item>
                    <TextField
                        label={"Nhập từ khóa"}
                    />

                </Grid>
                <Grid item>
                    <PopupDatePicker rangeTime={{ startDate, endDate }} onChangeDate={onChangeDate} />
                </Grid>
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-native-simple">Đầu mối</InputLabel>
                        <Select
                            native
                            value={state.age}
                            onChange={handleChange}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={10}>Ten</option>
                            <option value={20}>Twenty</option>
                            <option value={30}>Thirty</option>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="age-native-simple">Trạng thái</InputLabel>
                        <Select
                            native
                            value={state.age}
                            onChange={handleChange}
                            inputProps={{
                                name: 'age',
                                id: 'age-native-simple',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={10}>Ten</option>
                            <option value={20}>Twenty</option>
                            <option value={30}>Thirty</option>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item>
                    <Grid container justify={'flex-end'} style={{ marginTop: 10 }} >
                        <Button onClick={openModal} variant={'contained'} style={{ backgroundColor: Colors.green, color: Colors.white }} >
                            Tạo mới
                            </Button>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    )
}

export default VisiterView
