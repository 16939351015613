export const LOGIN = "LOGIN";
export const UPDATE_INFOR = "UPDATE_INFOR";
export const UPDATE_INFOR_FAILED = "UPDATE_INFOR_FAILED";
export const UPDATE_INFOR_SUCCESS = "UPDATE_INFOR_SUCCESS";

export const UPDATE_MENU_OPENCHECKIN = "UPDATE_MENU_OPENCHECKIN";
export const UPDATE_MENU_DEPARTMENT = "UPDATE_MENU_DEPARTMENT";
export const UPDATE_MENU_EMPLOYEE = "UPDATE_MENU_EMPLOYEE";
export const UPDATE_MENU_SALARY = "UPDATE_MENU_SALARY";
export const UPDATE_MENU_REPORT = "UPDATE_MENU_REPORT";

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
