
import WrapView from "../WrapView";
const SettingView=()=>{

    return(
        <WrapView>
            <h1>SettingView page</h1>
        </WrapView>

    )
}

export  default  SettingView
