import { Switch, Route, Link } from "react-router-dom";
import React, { useEffect } from "react";

import { connect } from "react-redux";
import HomeContainer from "./Home/HomeContainer";
import MeetingContainer from "./Meeting/MeetingContainer";
import ReportContainer from "./Report/ReportContainer";
import SeminarContainer from "./Seminar/SeminarContainer";
import SettingContainer from "./Setting/SettingContainer";
import VisitorContainer from "./Visiter/VisitorContainer";
import LoginScreen from "./Login/LoginScreen";

import AppBar from "./../Component/AppBar/Appbar";

const RootView = (props) => {
  console.log(props.user);
  return (
    <Switch>
      <Route path="/home" exact={true}>
        <HomeContainer />
      </Route>
      <Route path="/meeting">
        <MeetingContainer />
      </Route>
      <Route path="/report">
        <ReportContainer />
      </Route>
      <Route path="/seminar">
        <SeminarContainer />
      </Route>
      <Route path="/setting">
        <SettingContainer />
      </Route>
      <Route path="/visiter">
        <VisitorContainer />
      </Route>
      <Route path="/" exact={true}>
        <LoginScreen />
      </Route>
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

export default connect(mapStateToProps, {})(RootView);
