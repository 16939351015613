import React, { useEffect, useState } from "react";

import {
  Button,
  TextField,
  Grid,
  InputAdornment,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Card,
  FormHelperText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import KEY from '../../assets/AsynStorage'

import { AccountCircle, LockRounded } from "@material-ui/icons";

import { login } from '../../apis/Functions/users';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "260px",
    backgroundColor: "#3C8DBC",
  },
  root: {
    padding: 40,
  },
  loginTitle: {
    fontSize: "22px",
    color: "#3C8DBC",
    marginBottom: "10px",
  },
  container: {
    flex: 1,
    backgroundColor: "red",
  },
}));

const LoginScreen = () => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const classes = useStyles();
  let history = useHistory();

  const [state, setState] = useState({
    api_name: "api.v1.oauth",
    company_code: "Dcv",
    username: "",
    password: "",
  });

  const [helperText, sethelperText] = useState("");

  const Login = async () => {
    console.log("Login")

    if (state.username === "" || state.password === "") {
      sethelperText("");
      sethelperText("Cần phải điền đầy đủ email và password");
    } else {
      const res = await login(state);
      console.log(res)
      if (res.data) {
        localStorage.setItem(KEY.API_TOKEN, res.data.data.token);
        history.push("/home");
        sethelperText("");
      } else {
        sethelperText("");
        sethelperText("Sai email hoặc mật khẩu");
      }
    }
  };

  const height = getWindowDimensions().height;
  const width = getWindowDimensions().width;
  return (
    <Grid
      style={{
        height,
        backgroundImage: `url(https://images.pexels.com/photos/5905443/pexels-photo-5905443.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)`,
        backgroundRepeat: "no-repeat",
        width,
        backgroundSize: "cover",
      }}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Card className={classes.root}>
        <Grid container direction="column" justify="center" alignItems="center">
          <BorderColorIcon style={{ fontSize: "35px", marginBottom: "10px" }} />
          <b className={classes.loginTitle}>HỆ THỐNG QUẢN LÝ HỌP</b>
          <i>Bạn vui lòng, nhập đầy đủ thông tin</i>
          <TextField
            color="primary"
            label="Tên tài khoản"
            margin="normal"
            required={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={state.username}
            onChange={(e) => {
              setState({
                ...state,
                username: e.target.value,
              });
            }}
          />
          <TextField
            type="password"
            color="primary"
            required={true}
            name="password"
            id="password"
            autoComplete="current-password"
            label="Mật khẩu"
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockRounded />
                </InputAdornment>
              ),
            }}
            value={state.password}
            onChange={(e) => {
              setState({
                ...state,
                password: e.target.value,
              });
            }}
          />
          <div style={{ width: "100%" }}>
            <FormHelperText
              style={{ marginLeft: "16px", color: "red", fontSize: "13px" }}
            >
              {helperText}
            </FormHelperText>
          </div>

          <Button
            onClick={Login}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Đăng nhập
          </Button>
          <a href="/" style={{ color: "#3C8DBC" }}>
            Quên mật khẩu?
          </a>
        </Grid>
      </Card>
    </Grid>
  );
};

export default LoginScreen;
