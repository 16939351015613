/* eslint-disable handle-callback-err */
import {PostData, GetData, PostLogin} from "../helpers";
import url from "../url";

export const getOption= async (body) =>
    GetData(url.LOGIN, body)
        .then((res) => res)
        .catch((err) => err);


