import React from "react";
import Header from "./Header";
import { Grid, Container } from "@material-ui/core";
import Body from "./Body";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import WrapView from "../WrapView";
import KEY from '../../assets/AsynStorage'

const today = [
  {
    startTime: "10:30",
    nameMeeting: "Họp triển khai dự án mới",
    nameCreate: "doanpv",
    timeCreate: "09:30 15/12/2020",
    manageRoom: "P.Kỹ thuật",
    place: "Phòng họp lớn",
    participateCount: 10,
  },
  {
    startTime: "10:30",
    nameMeeting: "Họp triển khai dự án mới ReactJS",
    nameCreate: "ltrongtri",
    timeCreate: "09:30 15/12/2020",
    manageRoom: "P.Kỹ thuật",
    place: "Phòng họp lớn",
    participateCount: 10,
  },
];

const tommorrow = [
  {
    startTime: "11:30",
    nameMeeting: "Họp triển khai dự án mới",
    nameCreate: "doanpv",
    timeCreate: "09:30 15/12/2020",
    manageRoom: "P.Kỹ thuật",
    place: "Phòng họp lớn",
    participateCount: 10,
  },
  {
    startTime: "11:30",
    nameMeeting: "Họp triển khai dự án mới",
    nameCreate: "doanpv",
    timeCreate: "09:30 15/12/2020",
    manageRoom: "P.Kỹ thuật",
    place: "Phòng họp nhỏ",
    participateCount: 20,
  },
];

const HomeView = () => {
  console.log(localStorage.getItem(KEY.API_TOKEN))
  return (
      <WrapView>
    <Container>
      <div style={{ flexGrow: 1, padding: "15px" }}>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Header />
          </Grid>
          <Grid item sm={12}>
            <Body today={today} tommorrow={tommorrow} />
          </Grid>
        </Grid>
      </div>
    </Container>
      </WrapView>
  );
};

export default HomeView;
