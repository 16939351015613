import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { Button, Grid, Paper, Card, Typography } from "@material-ui/core";

import PeopleIcon from "@material-ui/icons/People";
import DomainIcon from "@material-ui/icons/Domain";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EmailIcon from "@material-ui/icons/Email";
import EventIcon from "@material-ui/icons/Event";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";

const HomeHeader = (props) => {
  return (
    <Grid container style={{ marginTop: 20 }}>
      <Grid container direction="row" spacing={3}>
        <Grid item lg={3} md={6} sm={12}>
          <Card
            style={{
              backgroundColor: "#dd4b39",
              paddingTop: 20,
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                color: "white",
                paddingLeft: 10,
                paddingRight: 10,
                position: "relative",
              }}
            >
              <Grid item>
                <CalendarViewDayIcon
                  style={{
                    width: 100,
                    height: 100,
                    color: "rgba(0,0,0,0.15)",
                    marginRight: "10px",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h3"> 3</Typography>
                <Typography variant="p">Hôm nay</Typography>
              </Grid>
              <Grid
                item
                style={{ position: "absolute", right: "2px", bottom: "4px" }}
              >
                <Button style={{ color: "white" }}>Chi tiết</Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={3} md={6} sm={12}>
          <Card
            style={{
              backgroundColor: "#f39c12",
              paddingTop: 20,
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                color: "white",
                paddingLeft: 10,
                paddingRight: 10,
                position: "relative",
              }}
            >
              <Grid item>
                <ArrowRightAltIcon
                  style={{
                    width: 100,
                    height: 100,
                    color: "rgba(0,0,0,0.15)",
                    marginRight: "10px",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h3"> 5</Typography>
                <Typography variant="p">Ngày mai</Typography>
              </Grid>
              <Grid
                item
                style={{ position: "absolute", right: "2px", bottom: "4px" }}
              >
                <Button style={{ color: "white" }}>Chi tiết</Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={3} md={6} sm={12}>
          <Card
            style={{
              backgroundColor: "#00c0ef",
              paddingTop: 20,
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                color: "white",
                paddingLeft: 10,
                paddingRight: 10,
                position: "relative",
              }}
            >
              <Grid item>
                <DateRangeIcon
                  style={{
                    width: 100,
                    height: 100,
                    color: "rgba(0,0,0,0.15)",
                    marginRight: "10px",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h3"> 8</Typography>
                <Typography variant="p">Tuần này</Typography>
              </Grid>
              <Grid
                item
                style={{ position: "absolute", right: "2px", bottom: "4px" }}
              >
                <Button style={{ color: "white" }}>Chi tiết</Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item lg={3} md={6} sm={12}>
          <Card
            style={{
              backgroundColor: "#00a65a",
              paddingTop: 20,
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                color: "white",
                paddingLeft: 10,
                paddingRight: 10,
                position: "relative",
              }}
            >
              <Grid item>
                <PersonIcon
                  style={{
                    width: 100,
                    height: 100,
                    color: "rgba(0,0,0,0.15)",
                    marginRight: "10px",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h3"> 6</Typography>
                <Typography variant="p">Tạo bởi tôi</Typography>
              </Grid>
              <Grid
                item
                style={{ position: "absolute", right: "2px", bottom: "4px" }}
              >
                <Button style={{ color: "white" }}>Chi tiết</Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeHeader;
