import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Container, Toolbar, AppBar, Typography, List, ListItem, ListItemText, Avatar,Menu,MenuItem, Link,Badge} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Colors from '../../assets/Color'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appbar:{
        ...theme.mixins.toolbar,
        backgroundColor:'white',
        color:'black',
    },
    row:{
        flexDirection:'row',
        backgroundColor:'blue',
        paddingTop:5,
        paddingBottom:5
    },
    item:{
        float:'left',
        paddingLeft:10,
        paddingRight:10,
        borderRight:'1px solid'
    },
    itemLast:{
        float:'left',
        paddingLeft:10,
        paddingRight:10,
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    customLink:{
        color:Colors.main
    },
    customLinkActive:{
        color:'green'
    }

}));

const ListLinks=[
    {name:"Trang chủ",page:"/home"},
    {name:"Cuộc họp",page:"/meeting"},
    {name:"Seminar",page:"/seminar"},
    {name:"Khách đăng ký",page:"/visiter"},
    {name:"Báo cáo",page:"/report"},
    {name:"Cài đặt",page:"/setting"},

]

export default function ButtonAppBar() {

    const [pageActive,setPageActive]=useState("/home")
    const classes = useStyles();

    useEffect(()=>{
        setPageActive(window.location.href.substring(21))
    })
    return (
        <AppBar className={classes.appbar} position="static">
            <Container   >
            <Toolbar  >
                <Container>
                    {ListLinks.map((e,index)=>{
                        if(index!=ListLinks.length-1)
                            return(
                                <div className={ classes.item} >
                                    <Link      aria-current={'page'} className={e.page==pageActive?classes.customLinkActive:classes.customLink}    href={e.page} >
                                            {e.name}
                                    </Link>
                                </div>
                            )
                        else
                            return (
                                <div className={classes.itemLast} >
                                    <Link aria-current={'page'}  className={e.page==pageActive?classes.customLinkActive:classes.customLink}   href={e.page} >
                                        {e.name}
                                    </Link>
                                </div>
                            )
                    })}

                </Container>
                <MenuItem>
                    <IconButton aria-label="show 11 new notifications" color="inherit">
                        <Badge badgeContent={11} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </MenuItem>
                <Avatar alt="Remy Sharp" className={classes.small} src="/static/images/avatar/1.jpg" />
            </Toolbar>
            </Container>
        </AppBar>
    );
}
