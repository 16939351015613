import React from "react";
// @ts-ignore
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

import rootReducer from "./reducers/index";
import RootView from "./View/RootView";

//redux saga
import createSagaMiddleware from "redux-saga";
import rootSaga from "./saga/rootSaga";

const sagaMiddleware = createSagaMiddleware();

let store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

function App() {
  return (
      <Provider store={store}>
        <RootView />
      </Provider>
  );
}

export default App;
