
import WrapView from "../WrapView";
const ReportView=()=>{

    return(
        <WrapView>
            <h1>ReportView page</h1>
        </WrapView>

    )
}

export  default  ReportView
