import React from 'react'

import SettingView from "./SettingView";



const SettingContainer=()=>{


    return(
        <SettingView/>
    )
}


export  default  SettingContainer;
