import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import MeetingView from "./MeetingView";
import { getListMeeting, createMeeting, deleteMeeting, editMeeting, filterMeeting } from "../../apis/Functions/meeting";
import { getDate, getDateOfMonth, getTime } from "../../config";
import { getOption } from "../../apis/Functions/option";

function createData(id, name, date, timeStart, timeEnd, location, level, creator, status, dateCreate) {
  return {
    id,
    name,
    date,
    timeStart,
    timeEnd,
    location,
    level,
    creator,
    status,
    dateCreate,
  };
}

const MeetingContainer = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [numberPage, setNumberPage] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [listMeeting, setListMeeting] = useState([]);
  const [rows, setRows] = useState([]);
  const [listRooms, setListRoms] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [listTypes, setListTypes] = useState([]);
  const [listLevel, setListLevel] = useState([]);
  const [listEmployee, setListEmployee] = useState([])
  const [listOption, setListOption] = useState([])
  const [listStatus, setListStatus] = useState([])
  const [itemSelected, setItemSelected] = useState({});

  const onSelectItem = (item) => {
    console.log("------------", item);
    console.log(listMeeting)
    const selected = listMeeting.find(e => e._id == item.id)
    setItemSelected(selected)
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);


  const closeUpdateModal = () => {
    setModalUpdate(false);
  };
  const openUpdateModal = () => {
    setModalUpdate(true);
  };

  const closeModalDelete = () => {
    setModalDelete(false);
  };
  const openModalDelete = () => {
    setModalDelete(true);
  };

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };


  const getAllOption = async () => {
    let res;
    res = await getOption({ api_name: "api.v1.category.room.list" })
    if (res.data.data) {
      setListRoms(res.data.data)
    }
    res = await getOption({ api_name: "api.v1.category.department.list" })
    if (res.data.data) {
      setListDepartment(res.data.data)
    }
    res = await getOption({ api_name: "api.v1.category.type.list" })
    if (res.data.data) {
      setListTypes(res.data.data)
    }
    res = await getOption({ api_name: "api.v1.category.level.list" })
    if (res.data.data) {
      setListLevel(res.data.data)
    }
    res = await getOption({ api_name: "api.v1.employee.list" })
    if (res.data.data) {
      setListEmployee(res.data.data)
    }
    res = await getOption({ api_name: "api.v1.category.option.list" })
    if (res.data.data) {
      setListOption(res.data.data)
    }
    res = await getOption({ api_name: "api.v1.category.option.list" })
    if (res.data.data) {
      setListStatus(res.data.data)
    }
  }


  useEffect(() => getData(), [pageCurrent])

  useEffect(() => {
    getData();
    getAllOption()
  }, []);

  useEffect(() => {
    const newList = listMeeting.map((e) =>
      createData(
        e._id,
        e.name,
        getDateOfMonth(e.start_time),
        getTime(e.start_time),
        getTime(e.end_time),
        e.room_name,
        e.level_name,
        e.created_by ? e.created_by.username : "",
        e.status_name,
        getDate(e.created_at)
      )
    );
    console.log(newList);
    setRows(newList);
  }, [listMeeting]);

  const getData = async () => {
    const res = await getListMeeting({
      api_name: "api.v1.features.meeting.list",
      page_index: pageCurrent
    });
    if (res.data.data) {
      setListMeeting(res.data.data);
      setNumberPage(res.data.meta.total_page)
    }
  };

  const submitCreateLetter = async (obj) => {
    if (obj) {
      const res = await createMeeting(obj)
      if (res.data.code == 400)
        enqueueSnackbar(res.data.message, { variant: "error" });
      else if (res.data.data && res.data.code == 200) {
        enqueueSnackbar("Thêm mới thành công!", { variant: "success" });
        getData()
        closeModal()
      }
    }
  }

  const deleteItem = async () => {
    closeModalDelete()
    console.log("in delete", itemSelected)
    const res = await deleteMeeting({ api_name: "api.v1.features.meeting.delete", _id: itemSelected._id })
    if (res.data.code == 400) {
      enqueueSnackbar(res.data.message, { variant: "error" });
    }
    else if (res.data.code == 200) {
      enqueueSnackbar("Xóa mới thành công!", { variant: "success" });
      getData()
    }
  }

  const submitUpdateMeeting = async (obj) => {
    if (obj) {
      console.log(obj)
      const res = await editMeeting(obj)
      console.log(res)
      if (res.data.code == 400)
        enqueueSnackbar(res.data.message, { variant: "error" });
      else if (res.data.data && res.data.code == 200) {
        enqueueSnackbar("Chỉnh sửa thành công!", { variant: "success" });
        getData()
        closeUpdateModal()
      }
    }
  }

  const getKeyWordFilter = async (text) => {
    const res = await filterMeeting({ api_name: "api.v1.features.meeting.list", keyword: text })
    if (res.data.data) {
      setListMeeting(res.data.data)
      setNumberPage(res.data.meta.total_page)
    }
  }

  const getLocationFilter = async (room) => {
    const res = await filterMeeting({ api_name: "api.v1.features.meeting.list", room: room })
    if (res.data.data) {
      setListMeeting(res.data.data)
      setNumberPage(res.data.meta.total_page)
    }
  }



  const changePageCurrent = (page) => setPageCurrent(page);

  return (
    <MeetingView
      numberPage={numberPage}
      pageCurrent={pageCurrent}
      changePageCurrent={changePageCurrent}
      rows={rows}
      listStatus={listStatus}
      listRooms={listRooms}
      listDepartment={listDepartment}
      listTypes={listTypes}
      listLevel={listLevel}
      listEmployee={listEmployee}
      listOption={listOption}
      submitCreateLetter={submitCreateLetter}
      submitUpdateMeeting={submitUpdateMeeting}
      modalIsOpen={modalIsOpen}
      openModal={openModal}
      closeModal={closeModal}
      modalUpdate={modalUpdate}
      closeUpdateModal={closeUpdateModal}
      openUpdateModal={openUpdateModal}
      deleteItem={deleteItem}
      onSelectItem={onSelectItem}
      modalDelete={modalDelete}
      closeModalDelete={closeModalDelete}
      openModalDelete={openModalDelete}
      itemSelected={itemSelected}
      getKeyWordFilter={getKeyWordFilter}
      getLocationFilter={getLocationFilter}
    />
  );
};

export default MeetingContainer;
