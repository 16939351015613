import React from 'react'

import HomeView from "./HomeView";

const HomeContainer=()=>{

    return(
        <HomeView/>
    )
}


export  default  HomeContainer;
