const KEY = {
    API_TOKEN: 'API_TOKEN',
    CAN_SHOW_RATING_DIALOG: 'CAN_SHOW_RATING_DIALOG',
    LANG_CODE: 'LANG_CODE',
    LANGUAGE: 'LANGUAGE',
    CHANGE_ROLE: '@CHANGE_ROLE'
};


export default  KEY;
