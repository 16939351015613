import React, { useState } from 'react';
import { makeStyles, Container, Typography, TextField, Grid, Card, InputLabel, Select, FormControl, Paper } from "@material-ui/core";

import Header from './Header';
import TableSeminar from './TableSeminar';
import { columns } from './Data';
import ModalDelete from '../../Component/Modal/ModalDelete';
import ModalSeminar from './ModalSeminar';
import WrapView from '../WrapView'
import UpdateSeminar from './UpdateSeminar';



const SeminarView = (props) => {


    const { numberPage, pageCurrent, changePageCurrent, rows, listStatus, listRooms, listDepartment, listTypes, listLevel,
        listEmployee, listOption, submitCreateSeminar, submitUpdateSeminar, modalIsOpen, openModal, closeModal, deleteItem, onSelectItem,
        modalDelete, closeModalDelete, openModalDelete, openUpdateModal, closeUpdateModal, modalUpdate, itemSelected
        , getKeyWordFilter, getLocationFilter } = props



    return (
        <WrapView>
            <Container >
                <Typography style={{ marginTop: 10, marginBottom: 10 }} variant={'h5'} >Danh sách Seminar</Typography>
                <Paper elevation={2}>
                    <Grid container >
                        <Grid xs={12} item >
                            <Header
                                openModal={openModal}
                                listRooms={listRooms}
                                listStatus={listStatus}
                                getKeyWordFilter={getKeyWordFilter}
                                getLocationFilter={getLocationFilter}
                            />
                        </Grid>
                        <Grid xs={12} item >
                            <TableSeminar
                                rows={rows}
                                columns={columns}
                                openModal={openModal}
                                openUpdateModal={openUpdateModal}
                                openModalDelete={openModalDelete}
                                onSelectItem={onSelectItem}
                                numberPage={numberPage}
                                pageCurrent={pageCurrent}
                                changePageCurrent={changePageCurrent}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <ModalSeminar
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                openModal={openModal}
                listRooms={listRooms}
                listDepartment={listDepartment}
                listTypes={listTypes}
                listLevel={listLevel}
                listEmployee={listEmployee}
                listOption={listOption}
                submitCreateSeminar={submitCreateSeminar}
            />
            <UpdateSeminar
                modalIsOpen={modalUpdate}
                closeModal={closeUpdateModal}
                openUpdateModal={openUpdateModal}
                listRooms={listRooms}
                listDepartment={listDepartment}
                listTypes={listTypes}
                listLevel={listLevel}
                listEmployee={listEmployee}
                listOption={listOption}
                submitUpdateSeminar={submitUpdateSeminar}
                itemSelected={itemSelected}
            />
            <ModalDelete
                modalIsOpen={modalDelete}
                closeModal={closeModalDelete}
                deleteItem={deleteItem}
            />
        </WrapView>
    )
}

export default SeminarView
