import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, TextField, Grid, Card, InputLabel, Select, FormControl, Button } from "@material-ui/core";
import PopupDatePicker from '../../Component/Popup/PopupDatePicker.js'
import Colors from '../../assets/Color'

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    containerAdd: {
        flex: 1,
    }

}));


const VisiterView = (props) => {
    const classes = useStyles();
    const { openModal, listRooms, listStatus, getKeyWordFilter, getLocationFilter } = props;

    const [roomSelected, setRomSelected] = useState("");
    const [creator, setCreator] = useState("")
    const [status, setStatus] = useState("")

    const [searchKeyWord, setSearchKeyWord] = useState("")

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const onChangeDate = (startDate, endDate) => {
        console.log(startDate + "----" + endDate);
        setStartDate(startDate)
        setEndDate(endDate)
    }

    useEffect(() => {
        getKeyWordFilter(searchKeyWord)
        getLocationFilter(roomSelected)
    }, [searchKeyWord, roomSelected])



    return (

        <div elevation={2} style={{ padding: 10, paddingRight: 20, paddingLeft: 20 }}>
            <Grid container xs={12} direction={'row'} spacing={3}>
                <Grid container item xs={5} direction={'row'} spacing={4}>
                    <Grid container xs={5} item>
                        <TextField
                            label={"Nhập từ khóa"}
                            value={searchKeyWord}
                            onChange={(event) => setSearchKeyWord(event.target.value)}
                        />
                    </Grid>
                    <Grid container xs={7} item>
                        <PopupDatePicker rangeTime={{ startDate, endDate }} onChangeDate={onChangeDate} />
                    </Grid>
                </Grid>

                <Grid container item xs={6} direction={'row'} spacing={5}>
                    <Grid container xs={4} item>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-native-simple">- Địa điểm -</InputLabel>
                            <Select
                                native
                                value={roomSelected}
                                onChange={(event) => setRomSelected(event.target.value)}
                                inputProps={{
                                    name: 'age',
                                    id: 'age-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                {listRooms.map(e => <option value={e.id}>{e.name}</option>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid container xs={4} item>
                        <FormControl className={classes.formControl}>
                            <TextField
                                label="Người tạo"
                                value={creator}
                                onChange={event => setCreator(event.target.value)}
                                type={'numbers'}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    <Grid container xs={4} item>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-native-simple">- Trạng thái -</InputLabel>
                            <Select
                                native
                                value={status}
                                onChange={(event) => setStatus(event.target.value)}
                                inputProps={{
                                    name: 'age',
                                    id: 'age-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                {listStatus.map(e => <option value={e.id}>{e.status_name}</option>)}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid className={classes.containerAdd} item>
                    <Grid container justify={'flex-end'} style={{ marginTop: 10 }} >
                        <Button
                            onClick={openModal}
                            variant={'contained'}
                            style={{ backgroundColor: Colors.green, color: Colors.white }}
                        >
                            Tạo mới
                        </Button>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    )
}

export default VisiterView
